import React from "react"
import styled from "@emotion/styled/macro"
import { theme } from "./Theme"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPostContent = props => {
  const BlogPostContainer = styled.div`
    width: 90%;
    max-width: 1010px;
    margin: 6.25rem auto 4.1875rem auto;

    a {
      color: ${theme.colors.primary};
    }

    .blogPostTitle {
      font-size: clamp(2rem, 1.4235rem + 2.4595vw, 4.375rem);
      line-height: 1;
      font-weight: 800;
      margin: 5.8125rem 0 2.4375rem 0 !important;
    }
    blockquote {
      padding-left: 2.125rem;
      border-left: 0.25rem solid ${theme.colors.primary};
    }
  `
  return (
    <BlogPostContainer className="postTemplate">
      {props.data.featuredImage !== (null || undefined || "") && (
        <GatsbyImage
          image={props.data.featuredImage}
          alt={props.data.altText}
        />
      )}
      <h1 className="blogPostTitle">{props.data.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: props.data.content }} />
    </BlogPostContainer>
  )
}
export { BlogPostContent }
